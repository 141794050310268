import {
    createElement,
    uniqueID,
    toCSS,
    destroyElement,
    getGlobal
} from '../../helpers';
import { CLASS, getContainerStyle } from './style';
import { EVENT, PROPS_EVENT, FULLSCREEN_LIB } from '../../constants';

export function DefaultContainerTemplate({ eventEmitter }) {
    return ({ tag, frame, prerenderFrame, event, dimensions: { width, height } }) => {
        if (!frame || !prerenderFrame) {
            return;
        }

        const uid = `euroland-tool-${tag}-${uniqueID()}`;
        const global = getGlobal();

        const container = createElement(
            'div',
            { id: uid },
            null,
            frame,
            prerenderFrame,
            createElement('style', { type: 'text/css' }, null, getContainerStyle({
                uid: uid,
                width: width,
                height: height
            }))
        );

        prerenderFrame.classList.add(CLASS.VISIBLE);
        frame.classList.add(CLASS.INVISIBLE);

        event.on(EVENT.RENDERED, () => {
            prerenderFrame.classList.remove(CLASS.VISIBLE);
            prerenderFrame.classList.add(CLASS.INVISIBLE);

            frame.classList.remove(CLASS.INVISIBLE);
            frame.classList.add(CLASS.VISIBLE);

            setTimeout(() => {
                destroyElement(prerenderFrame);
            }, 1);
        });

        event.on(EVENT.RESIZE, ({ width: newWidth, height: newHeight }) => {
            if (typeof newWidth === 'number') {
                container.style.width = toCSS(newWidth);
            }

            if (typeof newHeight === 'number') {
                container.style.height = toCSS(newHeight);
            }
        });

        if (eventEmitter) {
            eventEmitter.on(PROPS_EVENT.CHANGE_TITLE, (title) => frame.setAttribute('title', title));
            if (global[FULLSCREEN_LIB]) {
                eventEmitter.on(PROPS_EVENT.FULLSCREEN, () => global[FULLSCREEN_LIB].request(frame));
            }
        }

        return container;
    };
}