import { EUROLAND_TOOL } from '../../constants';

export const CLASS = {
    VISIBLE:   'visible',
    INVISIBLE: 'invisible'
};
export function invertColor(hex, bw) {
    const padZero = (str, len) => {
        len = len || 2;
        var zeros = new Array(len).join('0');
        return (zeros + str).slice(-len);
    };

    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    
    return '#' + padZero(r) + padZero(g) + padZero(b);
}

export function getContainerSandboxStyle({ hideCloseButton, backdrop, backdropBgColor, uid, dimensions: { width, height } }) {
    return `
        #${ uid } {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            width: 100vw;
            height: 100vh;
            max-width: 100%;
            max-height: 100%;
            min-width: 100%;
            min-height: 100%;
            z-index: 200000;
            outline: 0;
        }
        #${ uid} .${EUROLAND_TOOL }-modal-overlay {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: ${ backdrop ? backdropBgColor : 'transparent' };
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-x: hidden;
            overflow-y: auto;
        }
        #${ uid } a {
            color: #fff;
            text-decoration: none;
        }

        #${ uid } .${ EUROLAND_TOOL }-modal-overlay-close {
            position: absolute;
            right: 16px;
            top: 16px;
            width: 16px;
            height: 16px;
            opacity: 0.6;
            display: ${ hideCloseButton ? 'none' : 'block' }
        }
        #${ uid } .${ EUROLAND_TOOL }-modal-overlay-close:hover {
            opacity: 1;
        }
        #${ uid} .${ EUROLAND_TOOL }-modal-overlay-close:before,
        #${ uid} .${ EUROLAND_TOOL }-modal-overlay-close:after {
            position: absolute;
            left: 8px;
            content: ' ';
            height: 16px;
            width: 2px;
            background-color: #FFF;
        }
        #${ uid } .${ EUROLAND_TOOL }-modal-overlay-close:before {
            transform: rotate(45deg);
        }
        #${ uid } .${ EUROLAND_TOOL }-modal-overlay-close:after {
            transform: rotate(-45deg);
        }
        #${ uid} .${EUROLAND_TOOL}-modal-overlay-iframe-container {
            position: relative;
            width: ${ width };
            height: ${ height };
            margin: 1.75rem auto;
        }

        #${ uid} .${EUROLAND_TOOL}-modal-overlay-iframe-container > iframe {
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: opacity .2s ease-in-out;
            border-radius: 5px;
        }
        #${ uid} .${EUROLAND_TOOL}-modal-overlay-iframe-container > iframe.${ CLASS.INVISIBLE } {
            /*opacity: 0;*/
            animation: fadeOut 0.3s;
        }
        #${ uid} .${EUROLAND_TOOL}-modal-overlay-iframe-container > iframe.${ CLASS.VISIBLE } {
            /*opacity: 1;*/
            animation: fadeIn 0.3s;
        }

        @keyframes fadeIn {
            0% {opacity:0;}
            100% {opacity:1;}
        }
        
        @keyframes fadeOut {
            0% {opacity: 1;}
            100% {opacity: 0;} 
        }


        @media (max-width: 768px) {
            #${ uid} .${EUROLAND_TOOL}-modal-overlay-iframe-container {
                width: calc(100vw - 1rem);
                height: calc(100vh - 1rem);
                margin: .5rem;
            }
        }
    `;
}