import { KEY_CODES } from '../constants';
import { noop, uniqueID } from '../helpers';
import { ZalgoPromise } from 'zalgo-promise';

export function getBody() {
    var body = document.body;
    if (!body) throw new Error('Body element not found');
    return body;
}
export function isDocumentReady() {
    return Boolean(document.body) && 'complete' === document.readyState;
}
export function isDocumentInteractive() {
    return Boolean(document.body) && 'interactive' === document.readyState;
}

/**
 * 
 * @returns ZalgoPromise object
 */
export function waitForDocumentReady() {
    return new ZalgoPromise((resolve) => {
        if (isDocumentReady() || isDocumentInteractive()) {
            resolve();
        } else {
            var interval = setInterval(() => {
                if (isDocumentReady() || isDocumentInteractive()) {
                    clearInterval(interval);
                    resolve();
                }
            }, 10);
        }
    });
}

export function waitForWindowReady() {
    return new ZalgoPromise(resolve => {
        if (isDocumentReady()) {
            resolve();
        }

        window.addEventListener('load', () => resolve());
    });
}

export function htmlEncode(html = '') {
    return html.toString()
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/\//g, '&#x2F;');
}

export function isBrowser() {
    return (typeof window !== 'undefined') && window.location !== undefined;
}

export function querySelectorAll(selector, doc = window.document) {
    return Array.prototype.slice.call(doc.querySelectorAll(selector));
}


export function bindEvents(element, eventNames, handler) {

    for (const eventName of eventNames) {
        element.addEventListener(eventName, handler);
    }

    return {
        cancel: () => {
            for (const eventName of eventNames) {
                element.removeEventListener(eventName, handler);
            }
        }
    };
}

export function onClick(element, handler) {
    element.addEventListener('touchstart', noop);
    element.addEventListener('click', handler);
    element.addEventListener('keypress', (event) => {
        if (event.keyCode === KEY_CODES.ENTER || event.keyCode === KEY_CODES.SPACE) {
            return handler(event);
        }
    });
}


export function isNode(o) {
    return (typeof Node === 'object' && o instanceof Node)
        || (o && typeof o === 'object' && typeof o.nodeType === 'number' && typeof o.nodeName === 'string');
}

//Returns true if it is a DOM element    
export function isElement(o) {
    return (typeof HTMLElement === 'object' && o instanceof HTMLElement)
        || (o && typeof o === 'object' && o !== null && o.nodeType === 1 && typeof o.nodeName === 'string');
}

export function getElementSafe(selector, doc = document) {

    if (isElement(selector)) {
        return selector;
    }

    if (typeof selector === 'string') {
        return doc.querySelector(selector);
    }
}

export function getElement(selector, doc = document) {

    const element = getElementSafe(selector, doc);

    if (element) {
        return element;
    }

    throw new Error('Can not find element with provided selector');
}

const matches = HTMLElement.prototype.matches
    || HTMLElement.prototype.webkitMatchesSelector
    || HTMLElement.prototype.mozMatchesSelector
    || HTMLElement.prototype.msMatchesSelector;

const matchesSelector = (elm, sl) => elm instanceof HTMLElement && matches.call(elm, sl);
const matchNodeRecursively = (nodes, selector, callback) => {
    const addedNodes = [...nodes];
    const nodeMatches = addedNodes
        .filter(node => node.nodeType === 1)
        .reduce((acc, node) => {
            // If current node is matched with provide selector
            if (matchesSelector(node, selector)) {
                acc = acc.concat([node]);
            }
            // Otherwise, try to match the selector with children of current node.
            // This usually happens if the children is appended via innerHTML attribute,
            // and MutationObserver is not going to fire with this case.
            else {
                var childList = [...node.querySelectorAll(selector)];
                if (childList.length) {
                    acc = acc.concat(childList);
                }
            }
            return acc;
        }, []);

    if (nodeMatches.length) {
        callback(nodeMatches);
    }
};

export function waitForElementReady(selector, doc = document) {
    const promise = new ZalgoPromise();

    if (selector && selector.nodeType === 1) {
        return promise.resolve(selector);
    }

    if (!selector || Object.prototype.toString.call(selector) !== '[object String]') {
        return promise.reject('Must provide a valid selector');
    }

    const bodyObserver = new MutationObserver((records) => {
        records.forEach(record => {
            if (record.type !== 'childList' || !record.addedNodes || !record.addedNodes.length) {
                return;
            }

            matchNodeRecursively(record.addedNodes, selector, (nodes) => {
                promise.resolve(nodes);
                bodyObserver.disconnect();
            });
        });
    });

    bodyObserver.observe(getBody(), {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false
    });

    // Try query selector at the first load
    const firstLoadMatches = [...doc.querySelectorAll(selector)];
    if (firstLoadMatches.length) {
        promise.resolve(firstLoadMatches);
        bodyObserver.disconnect();
    }

    return promise;
}

export function watchForever({ selector = '[data-euroland-tool]', callback = noop, doc = document }) {
    
    if (!selector || Object.prototype.toString.call(selector) !== '[object String]') {
        throw new Error('Must provide a valid selector');
    }

    const bodyObserver = new MutationObserver((records) => {
        records.forEach(record => {
            if (record.type !== 'childList') {
                return;
            }

            if (!record.addedNodes || !record.addedNodes.length) {
                return;
            }

            matchNodeRecursively(record.addedNodes, selector, (nodes) => {
                callback(nodes);
            });
        });
    });

    bodyObserver.observe(getBody(), {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false
    });

    return {
        cancel: () => bodyObserver.disconnect()
    };
}

export function isShadowElement(node) {
    do {
        // ShadowDOM Ref: http://www.w3.org/TR/shadow-dom/#shadowroot-object
        if (node.nodeType === 11) {
            return true;
        }
    } while ((node = node.parentNode));

    return false;
}

export function getShadowHost(node) {
    do {
        if (node.nodeType === 11) {
            return node.host;
        }
    } while ((node = node.parentNode));

    return null;
}


export function insertShadowSlot(element) {
    const shadowHost = getShadowHost(element);

    if (!shadowHost) {
        throw new Error('Element is not in shadow dom');
    }

    const slotName = `shadow-slot-${uniqueID()}`;
    const slot = document.createElement('slot');
    slot.setAttribute('name', slotName);
    element.appendChild(slot);

    const slotProvider = document.createElement('div');
    slotProvider.setAttribute('slot', slotName);
    shadowHost.appendChild(slotProvider);

    if (isShadowElement(shadowHost)) {
        return insertShadowSlot(slotProvider);
    }

    return slotProvider;
}

export function makeElementVisible(element) {
    element.style.setProperty('visibility', '');
}

export function makeElementInvisible(element) {
    element.style.setProperty('visibility', 'hidden', 'important');
}


export function showElement(element) {
    element.style.setProperty('display', '');
}

export function hideElement(element) {
    element.style.setProperty('display', 'none', 'important');
}

export function destroyElement(element) {
    if (element && element.parentNode) {
        element.parentNode.removeChild(element);
    }
}

export function addClass(element, name) {
    element.classList.add(name);
}

export function removeClass(element, name) {
    element.classList.remove(name);
}

export function setStyle(el, styleText, doc = window.document) {
    if (el.styleSheet) {
        el.styleSheet.cssText = styleText;
    } else {
        el.appendChild(doc.createTextNode(styleText));
    }
}

export const isRTL = () => document.documentElement.dir === 'rtl';