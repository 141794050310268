export const PROTOCOL = {
    MOCK: 'mock:',
    FILE: 'file:',
    ABOUT: 'about:'
};

export const WILDCARD = '*';

export const WINDOW_TYPE = {
    IFRAME: 'iframe',
    POPUP:  'popup'
};

export const DEFAULT_DIMENSIONS = {
    WIDTH:  '300px',
    HEIGHT: '150px'
};

export const EVENT = {
    RENDER:   'zoid-render',
    RENDERED: 'zoid-rendered',
    DISPLAY:  'zoid-display',
    ERROR:    'zoid-error',
    CLOSE:    'zoid-close',
    DESTROY:  'zoid-destroy',
    PROPS:    'zoid-props',
    RESIZE:   'zoid-resize',
    FOCUS:    'zoid-focus',
    DEF_REQUEST: 'tool-definition-request'
};

export const WINDOW_EVENT = {
    CREATE: 'window-create',
    CREATED: 'window-created'
};

export const PROPS_EVENT = {
    CHANGE_TITLE: 'titleChange',
    FULLSCREEN: 'fullscreen',
    SCROLL_TO: 'scrollTop'
};

export const METHOD = {
    GET: 'get',
    POST: 'post'
};

export const KEY_CODES = {
    ENTER: 13,
    SPACE: 32
};

export const EUROLAND_TOOL = 'euroland-tool';

export const FULLSCREEN_LIB = 'fullscreenLib';

/**
 * Provives ability to disable watching element on host site.
 * Watching element on host site enabling the embed.js can monitor
 * and create a tool on the fly. This is handly for the host site which
 * is SPA, but not good at performance. If host site has any issue on the 
 * performance, they can set if to False by providing this variable before
 * embed.js is executed. 
 */
export const DISABLE_LIVE_WATCH = '__EUROLAND_DISABLE_LIVE_WATCH_ELEMENT__';
