import { noop } from '../helpers';
import { ZalgoPromise } from 'zalgo-promise';

export function cleanup(obj) {
    const tasks = [];
    obj = obj || {};
    
    let cleaned = false;
    let cleanErr;
    const cleaner = {
        set: function(name, item) {
            if (!cleaned) {
                obj[name] = item;
                cleaner.register(() => {
                    delete obj[name];
                });
            }
            return item;
        },
        register: function(func) {
            if (cleaned) {
                func(cleanErr);
            } else {
                tasks.push(() => func(cleanErr));
            }
        },
        /**
         * 
         * @param {String} err 
         * @returns ZalgoPromise object
         */
        all: function(err) {
            cleanErr = err;
            const results = [];
            cleaned = true;
            while (tasks.length) {
                const task = tasks.shift();
                results.push(task());
            }
            return ZalgoPromise.all(results).then(noop);
        }
    };
    return cleaner;
}