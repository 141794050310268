import { DISABLE_LIVE_WATCH } from '../constants';

/**
 * Determines whether disable watching element on host site.
 * Watching element on host site enabling the embed.js can monitor
 * and create a tool on the fly. This is handly for the host site which
 * is SPA, but not good at performance. If host site has any issue on the 
 * performance, they can set if to False by providing this variable before
 * embed.js is executed. 
 */
export function isLiveWatchEleemntDisabled(win = window) {
    return win[DISABLE_LIVE_WATCH] === true;
}