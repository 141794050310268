import {
    waitForElementReady,
    isIFrame,
    getToolUrlBase,
    uniqueID,
    toCSS,
    noop,
    isString,
    isPerc,
    stringifyError,
    createElement,
    destroyElement,
    getGlobal,
    EventEmitter,
    htmlEncode,
    parseQuery
} from '../helpers';
import { EVENT, EUROLAND_TOOL, FULLSCREEN_LIB, PROPS_EVENT } from '../constants';
import { LeafComponentFactory } from './componentdef-factory';
import { defaultPrerenderTemplate } from './default-prerender-template';
import { ZalgoPromise } from 'zalgo-promise';

/**
 * ToolComponent which embeding tool to the customer site inside an iframe 
 * and setting up the features like auto-resize, exchanges cross-origin message between
 * parent and child iframe, etc.
 */

const CLASS = {
    VISIBLE:   'visible',
    INVISIBLE: 'invisible'
};

function ToolCompomentContainerTemplate({eventEmitter, tag, frame, prerenderFrame, event, dimensions: { width, height } }) {
    if (!frame || !prerenderFrame) {
        return;
    }

    const uid = `euroland-tool-${tag}-${uniqueID()}`;
    const global = getGlobal();

    const containerStyle = `
        #${ uid } {
            display: inline-block;
            position: relative;
            width: ${ width };
            height: ${ height };
        }

        #${ uid } > iframe {
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: opacity .2s ease-in-out;
        }

        #${ uid } > iframe.${ CLASS.INVISIBLE } {
            opacity: 0;
        }

        #${ uid } > iframe.${ CLASS.VISIBLE } {
            opacity: 1;
    }`;

    const container = createElement(
        'div',
        { id: uid },
        null,
        frame,
        prerenderFrame,
        createElement('style', { type: 'text/css' }, null, containerStyle)
    );

    prerenderFrame.classList.add(CLASS.VISIBLE);
    frame.classList.add(CLASS.INVISIBLE);
        
    event.on(EVENT.RENDERED, () => {
        prerenderFrame.classList.remove(CLASS.VISIBLE);
        prerenderFrame.classList.add(CLASS.INVISIBLE);

        frame.classList.remove(CLASS.INVISIBLE);
        frame.classList.add(CLASS.VISIBLE);

        setTimeout(() => {
            destroyElement(prerenderFrame);
        }, 1);
    });
    
    event.on(EVENT.RESIZE, ({ width: newWidth, height: newHeight }) => {
        if (typeof newWidth === 'number') {
            container.style.width = toCSS(newWidth);
        }

        if (typeof newHeight === 'number') {
            container.style.height = toCSS(newHeight);
        }
    });

    if (eventEmitter) {
        eventEmitter.on(PROPS_EVENT.CHANGE_TITLE, (title) => {
            frame.title = title;
        });
        if (global[FULLSCREEN_LIB]) {
            eventEmitter.on(PROPS_EVENT.FULLSCREEN, () => {
                global[FULLSCREEN_LIB].request(frame);
            });
        }

        eventEmitter.on(PROPS_EVENT.SCROLL_TO, (scrollTop) => {
            if (typeof (scrollTop) !== 'undefined'
                && !isNaN(scrollTop)
                && isFinite(scrollTop)
                && scrollTop >= 0) {
                window.scrollTo({
                    left: 0, 
                    top: scrollTop, 
                    behavior: 'smooth'
                });
            } else if(['top-frame', 'bottom-frame'].indexOf(scrollTop) !== -1) {
                frame.scrollIntoView({
                    behavior: 'smooth', 
					block: scrollTop === 'top-frame' ? 'start' : 'end', 
					inline: 'nearest'
                });
            } 
        });
    }

    return container;
}

export function ToolComponent({
    urlBase,
    toolName,
    absoluteToolUrl,
    companyCode,
    lang,
    toolVer,
    width,
    height,
    attributes,
    allowedParentDomains = '*',
    resizeElement = 'body'
}) {
    const global = getGlobal();
    urlBase = isString(urlBase) && urlBase.length > 0 ? urlBase : getToolUrlBase();
    let eventEmitter;
    const screenfullLib = getGlobal()[FULLSCREEN_LIB];

    const isHttpUrl = (value) => {
        return isString(value) && (value.match(/^https?:\/\//i) || value.match(/^mock:\/\//));
    };

    const propsDef = {
        toolName: {
            type: 'string',
            required: true
        },
        toolUrl: {
            type: 'string',
            required: false,
            validate: function ({ value }) {
                return !isString(value) || !value.length || isHttpUrl(value);
            }
        },
        companyCode: {
            type: 'string',
            required: true,
            queryParam: true
        },
        lang: {
            type: 'string',
            required: false,
            queryParam: true,
            default: () => ('en-gb')
        },
        toolVer: {
            type: 'string',
            required: false,
            queryParam: false,
            default: () => ('')
        },
        'v':  {
            type: 'string',
            required: false,
            queryParam: true,
            alias: 'toolVer'
        },
        origin: {
            type: 'string',
            required: true,
            queryParam: true
        },
        onCreateComponent: {
            type: 'function',
            required: false,
            default: () => noop
        },
        requestFullscreen: {
            type: 'function',
            required: false,
            default: () => noop
        },
        exitFullscreen: {
            type: 'function',
            required: false,
            default: () => noop
        },
        maximize: {
            type: 'function',
            required: false,
            default: () => noop
        },
        unMaximize: {
            type: 'function',
            required: false,
            default: () => noop
        },
        setTitle: {
            type: 'function',
            required: false,
            default: () => noop
        },
        scrollTop: {
            type: 'function',
            required: false,
            default: () => window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop || 0
        }
    };

    const getSafeWidth = () => {
        try {
            return toCSS(width);
        } catch (err) {
            return '100%';
        }
    };

    const getSafeHeight = () => {
        try {
            return toCSS(height);
        } catch (err) {
            return '100%';
        }
    };

    const getUrl = ({ props }) => {
        const { toolName, toolUrl/*, companyCode, lang, toolVer, origin*/ } = props;
        
        return isHttpUrl(toolUrl)
            ? `${toolUrl}`
            : new URL(`${toolName}/`, urlBase).href;
    };
   
    const createTreeComponent = () => {
        const treeComponentTagName = toolName.toLowerCase();
        let component = zoid.create({
            url: getUrl,
            tag: treeComponentTagName,
            allowedParentDomains: allowedParentDomains,
            attributes: {
                iframe: {
                    allowfullscreen: 'true',
                    scrolling: 'no'
                }
            },
            dimensions: {
                width: getSafeWidth(),
                height: getSafeHeight()
            },
            autoResize: {
                width: false /*isPerc(getSafeWidth())*/, // autoResize width get strange behavior, disable it this time.
                height: isPerc(getSafeHeight()),
                element: resizeElement || 'body'
            },
            containerTemplate: ({ tag, frame, prerenderFrame, event, dimensions: { width, height } }) => {
                return ToolCompomentContainerTemplate({
                    eventEmitter: eventEmitter,
                    tag: tag,
                    frame: frame,
                    prerenderFrame: prerenderFrame,
                    event: event,
                    dimensions: { width, height }
                });
            },
            prerenderTemplate: defaultPrerenderTemplate,
            props: propsDef
        });
        
        return component;
    };

    const createLayout = () => {
        const uid = uniqueID();
        const topUID = `${EUROLAND_TOOL}-layout-top-${uid}`;
        const bottomUID = `${EUROLAND_TOOL}-layout-bottom-${uid}`;
        const middleUID = `${EUROLAND_TOOL}-layout-middle-${uid}`;

        const style = `
            #${topUID}, #${bottomUID}, #${middleUID} {
                flex: 1 100%;
                border: none;
            }
        `;
    
        return {
            topContainer: createElement('div', { id: topUID }),
            middleContainer: createElement('div', { id: middleUID }),
            bottomContainer: createElement('div', { id: bottomUID }),
            style: createElement('style', { type: 'text/css' }, null, style)
        };
    };

    const treeComponent = createTreeComponent();
    const isChild = treeComponent.isChild();

    if (!isChild) {
        eventEmitter = new EventEmitter();
    }
    
    const createLeafComponent = ({ componentName, template, options }) => {
        LeafComponentFactory(componentName, template, { ...options });
    };

    const requestFullscreen = (selector) => {
        if (!screenfullLib) {
            return ZalgoPromise.reject('screenfull lib not available.');
        }

        if (!screenfullLib.enabled) {
            return ZalgoPromise.reject('Current browser does not support fullscreen');
        }

        if (eventEmitter) {
            eventEmitter.emit(PROPS_EVENT.FULLSCREEN);
            return ZalgoPromise.resolve();
        }
    };

    const exitFullscreen = () => {
        if (!screenfullLib || !screenfullLib.enabled) {
            return ZalgoPromise.resolve();
        } else {
            return screenfullLib.exit();
        }
    };

    const setTitle = (title) => {
        if (!isString(title)) {
            return;
        }
        title = htmlEncode(title);
        if (eventEmitter) {
            eventEmitter.emit(PROPS_EVENT.CHANGE_TITLE, title);
        }
    };

    const scrollTop = (scrollTopNumber) => {
        if(scrollTopNumber !== undefined && scrollTopNumber !== null) {
            eventEmitter && eventEmitter.emit(PROPS_EVENT.SCROLL_TO, scrollTopNumber);
        }
        else {
            return window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop || 0;
        }
    };

    const render = (container) => {
        if (isChild) {
            console.warn('You\'re sitting in an iframe. `euroland.render()` aims to call from parent site only.');
            return ZalgoPromise.reject(new Error('You\'re sitting in an iframe. `euroland.render()` aims to call from parent site only.'));
        }

        return waitForElementReady(container).then(el => {
            let containerId = el.id || '';

            if (!containerId.length) {
                containerId = uniqueID();
                el.id = containerId;
            }

            const { topContainer, bottomContainer, middleContainer, style } = createLayout();
            el.appendChild(topContainer);
            el.appendChild(middleContainer);
            el.appendChild(bottomContainer);
            el.appendChild(style);

            return treeComponent({
                layout: {
                    top: `#${topContainer.id}`,
                    middle: `#${middleContainer.id}`,
                    bottom: `#${ bottomContainer.id }`
                },
                toolName: toolName,
                toolUrl: absoluteToolUrl,
                companyCode: companyCode,
                toolVer: toolVer,
                lang: lang,
                origin: location.origin,
                onCreateComponent: function onCreateComponent({ props }) {
                    createLeafComponent({ ...props });
                },
                requestFullscreen: requestFullscreen,
                exitFullscreen: exitFullscreen,
                setTitle: setTitle,
                scrollTop: scrollTop
            }).render(middleContainer, 'iframe');

        }).catch(err => {
            console.error(`Could not render tool "${toolName}" to the document. Error: ${stringifyError(err)}`);
            throw err;
        });
    };

    const destroyAll = () => {
        zoid.destroy('Destroy all components');
    };

    return {
        render: render,
        destroy: destroyAll
    };
}