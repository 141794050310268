import { getGlobal } from './global';

const global = getGlobal();
global['____debug___'] = false;

const setDebug = (enabled = false) => {
    global['____debug___'] = enabled;
};

const isDebug = () => global['____debug___'];

export {
    setDebug,
    isDebug
};