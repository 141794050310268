export function defaultPrerenderTemplate({ doc, props }) {
    const html = doc.createElement('html');
        const body = doc.createElement('body');
        const style = doc.createElement('style');
        const spinner = doc.createElement('div');
        const tallMan = doc.createElement('div');
        
        tallMan.classList.add('tall-man');
        spinner.classList.add('spinner', 'tall-man');

        if (props.cspNonce) {
            style.setAttribute('nonce', props.cspNonce);
        }

        html.appendChild(body);
        body.appendChild(tallMan);
        body.appendChild(spinner);
        body.appendChild(style);
        style.appendChild(doc.createTextNode(`
            html, body {
                box-sizing: border-box;
                background-color: #FFF;
                overflow: hidden;
            }
            .tall-man {
                height: 60px;
            }
            .spinner {
                position: fixed;
                max-height: 60vmin;
                max-width: 60vmin;
                width: 60px;
                top: 50%;
                left: 50%;
                box-sizing: border-box;
                border: 3px solid rgba(0, 0, 0, .2);
                border-top-color: rgba(33, 128, 192, 0.8);
                border-radius: 100%;
                animation: rotation .7s infinite linear;
            }

            @keyframes rotation {
                from {
                    transform: translateX(-50%) translateY(-50%) rotate(0deg);
                }
                to {
                    transform: translateX(-50%) translateY(-50%) rotate(359deg);
                }
            }
        `));

        return html;
}