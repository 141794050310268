import {
    createElement,
    uniqueID,
    animate,
    supportsPopups,
    isFirefox,
    noop,
    toCSS,
    isIos,
    destroyElement,
    setStyle,
    bindEvents
} from '../../helpers';
import { CLASS, getSandboxStyle, getContainerStyle } from './style';
import { EVENT, WINDOW_TYPE } from '../../constants';

export function OverlayContainerTemplate({ autoResize = true, hideCloseButton = false, backdropBgColor, ignoreBackdropClick = false, styles = {} }) {
    return function ({ context, close, doc, props, focus, event, frame, prerenderFrame, dimensions: { width, height } }) {
        const uid = `euroland-tool-overlay-${uniqueID()}`;
        const overlay = createElement('div', {
            'id': uid,
            'class': 'euroland-tool-overlay-sandbox'
        });

        let sandboxIFrame = null;
        let enforceFocusEvent = null;

        function closePopup(e) {
            e.preventDefault();
            e.stopPropagation();
            close();
        }

        function focusPopup(e) {
            e.preventDefault();
            e.stopPropagation();

            if (ignoreBackdropClick) {
                return;
            }

            if (context === WINDOW_TYPE.IFRAME) {
                // Close popup on backdrop clicked.
                if (e.currentTarget === e.target) {
                    close();
                }

                return;
            }

            if (!supportsPopups()) {
                return;
            }

            if (isIos()) {
                window.alert('Please switch tabs to reactivate the window');
            } else if (isFirefox()) {
                window.alert('Don\'t see the popup window?\n\nSelect "Window" in your toolbar to find out');
            } else {
                focus();
            }
        }

        const enforceFocus = () => {
            if (enforceFocusEvent) enforceFocusEvent.cancel();
            bindEvents(document, ['focusin'], () => {
                console.log('Parent document focused!');
            });

            enforceFocusEvent = bindEvents(sandboxIFrame.contentWindow.document, ['focusin'], (event) => {
                console.log('Sandbox document focused!');
                if (sandboxIFrame
                    && document.activeElement !== sandboxIFrame) {
                    console.log('SandboxIFrame focus');
                    if (sandboxIFrame.contentWindow) {
                        sandboxIFrame.contentWindow.focus();
                    }
                }
            });
            bindEvents(frame.contentWindow.document, ['focusin'], (event) => {
                console.log('Frame document focused!');
            });
            if (frame && frame.contentWindow) {
                frame.contentWindow.focus();
            }
        };

        const unenforceFocus = () => {
            if (enforceFocusEvent) {
                enforceFocusEvent.cancel();
                enforceFocusEvent = null;
            }
        };

        const setupAnimations = (name, el) => {
            const showContainer = () => animate(el, `show-${name}`, noop);
            const hideContainer = () => animate(el, `hide-${name}`, noop);
            event.on(EVENT.DISPLAY, showContainer);
            event.on(EVENT.CLOSE, hideContainer);
        };

        const setupAutoResize = (el) => {
            event.on(EVENT.RESIZE, ({ width: newWidth, height: newHeight }) => {
                if (typeof newWidth === 'number') {
                    el.style.width = toCSS(newWidth);
                }

                if (typeof newHeight === 'number') {
                    el.style.height = toCSS(newHeight);
                }
            });
        };
    
        const outletOnRender = (el) => {
            setupAnimations('component', el);
            if (autoResize) {
                setupAutoResize(el);
            }
        };
            
        let outlet = null;

        if (frame && prerenderFrame) {
            frame.classList.add(CLASS.COMPONENT_FRAME);
            prerenderFrame.classList.add(CLASS.PRERENDER_FRAME);
        
            prerenderFrame.classList.add(CLASS.VISIBLE);
            frame.classList.add(CLASS.INVISIBLE);
    
            event.on(EVENT.RENDERED, () => {
                prerenderFrame.classList.remove(CLASS.VISIBLE);
                prerenderFrame.classList.add(CLASS.INVISIBLE);
    
                frame.classList.remove(CLASS.INVISIBLE);
                frame.classList.add(CLASS.VISIBLE);
    
                setTimeout(() => {
                    destroyElement(prerenderFrame);
                }, 1);
            });
        
            outlet = createElement('div',
                {
                    'class': CLASS.OUTLET
                },
                null,
                frame,
                prerenderFrame
            );
            outletOnRender(outlet);

        }
    
        const sandboxStyle = createElement('style', { 'type': 'text/css' }, null);
        setStyle(sandboxStyle, getSandboxStyle({ uid: uid }));

        const containerStyle = createElement('style', { 'type': 'text/css' }, null);
        setStyle(containerStyle, getContainerStyle({ uid: uid, width: width, height: height, backdropBgColor: backdropBgColor }));

        sandboxIFrame = createElement('iframe', {
            'title': 'Euroland tool overlay',
            'name': `euroland_tool_overlay_sandbox_${uid}`,
            'scrolling': 'no',
            'allowtransparency': 'true',
            'class': 'euroland-tool-overlay-sandbox-iframe'
        },
            null,
            createElement('html', null, null,
                createElement('body', null, null,
                    createElement('div', {
                        'id': uid,
                        'class': `euroland-tool-overlay-context-${context}`
                    }, {
                        'click': focusPopup
                    },
                        (!hideCloseButton ?
                            createElement('a', {
                                'href': '#',
                                'class': 'euroland-tool-overlay-close',
                                'aria-label': 'close',
                                'role': 'button'
                            }, {
                                'click': closePopup
                            })
                            : ''
                        ),
                        createElement('div', { 'class': 'euroland-tool-overlay-iframe-container' }, null, outlet),
                        containerStyle
                    )
                )
            )
        );

        overlay.appendChild(sandboxStyle);
        overlay.appendChild(sandboxIFrame);

        setupAnimations('container', overlay);

        return overlay;
    };
}