import { isIE } from '../../helpers';

export const CLASS = {
    OUTLET:          'outlet',
    VISIBLE:         'visible',
    INVISIBLE:       'invisible',
    COMPONENT_FRAME: 'component-frame',
    PRERENDER_FRAME: 'prerender-frame'
};

export function getSandboxStyle({ uid }){
    return `
        #${ uid }.euroland-tool-overlay-sandbox {
            display: block;
            position: fixed;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            width: 100vw;
            height: 100vh;
            max-width: 100%;
            max-height: 100%;
            min-width: 100%;
            min-height: 100%;

            z-index: 2147483647;

            animation-duration: 0.3s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards !important;
            opacity: 0;
        }

        #${ uid }.euroland-tool-overlay-sandbox .euroland-tool-overlay-sandbox-iframe {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: none;
        }

        @keyframes show-container {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }

        @keyframes hide-container {
            from {
                opacity: 1;
            }

            50% {
                opacity: 1;
            }

            to {
                opacity: 0;
            }
        }
    `;
}

export function getContainerStyle({ uid, width, height, backdropBgColor }) {
    return `
        #${ uid } {
            position: absolute;
            z-index: 2147483647;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: ${backdropBgColor};
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        #${ uid } a {
            color: #fff;
        }

        #${ uid } .euroland-tool-overlay-close:before,
        #${ uid } .euroland-tool-overlay-close:after {
            background-color: #fff;
        }

        #${ uid }.euroland-tool-overlay-context-popup {
            cursor: pointer;
        }

        #${ uid } a {
            text-decoration: none;
        }

        #${ uid } .euroland-tool-overlay-modal {
            box-sizing: border-box;
            max-width: 350px;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            cursor: pointer;
            text-align: center;
        }

        .euroland-tool-overlay-loader {
            display: none;
        }

        #${ uid }.euroland-tool-overlay-loading .euroland-tool-overlay-loader {
            display: block;
        }

        #${ uid } .euroland-tool-overlay-close {
            position: absolute;
            right: 16px;
            top: 16px;
            width: 16px;
            height: 16px;
            opacity: 0.6;
        }

        #${ uid } .euroland-tool-overlay-close:hover {
            opacity: 1;
        }

        #${ uid } .euroland-tool-overlay-close:before, .euroland-tool-overlay-close:after {
            position: absolute;
            left: 8px;
            content: ' ';
            height: 16px;
            width: 2px;
        }

        #${ uid } .euroland-tool-overlay-close:before {
            transform: rotate(45deg);
        }

        #${ uid } .euroland-tool-overlay-close:after {
            transform: rotate(-45deg);
        }

        #${ uid } .euroland-tool-overlay-iframe-container {
            display: none;
        }

        #${ uid }.euroland-tool-overlay-context-iframe .euroland-tool-overlay-iframe-container,
        #${ uid }.euroland-tool-overlay-context-iframe .euroland-tool-overlay-iframe-container > .${ CLASS.OUTLET },
        #${ uid }.euroland-tool-overlay-context-iframe .euroland-tool-overlay-iframe-container > .${ CLASS.OUTLET } > iframe {
            max-height: 95vh;
            max-width: 95vw;
        }

        #${ uid }.euroland-tool-overlay-context-iframe .euroland-tool-overlay-iframe-container {
            border-radius: .3rem;
            border: 1px solid rgba(0,0,0,.2);
            outline: 0;
            display: block;
            min-width: 450px;
            position: ${ isIE() ? 'relative' : 'absolute' };
            overflow: hidden;
        }

        #${ uid }.euroland-tool-overlay-context-iframe .${ CLASS.OUTLET } {

            position: relative;

            transition: all 0.3s ease;
            animation-duration: 0.3s;
            animation-fill-mode: forwards !important;

            min-width: 450px;
            max-width: 450px;
            width: ${width};
            height: ${height};

            background-color: #FFF;
            overflow: auto;
            opacity: 0;
            -webkit-overflow-scrolling: touch;
            transition: -webkit-transform .3s ease-out;
            transition: transform .3s ease-out;
            transition: transform .3s ease-out,-webkit-transform .3s ease-out;
            -webkit-transform: translate(0,-25%);
            -ms-transform: translate(0, -25%);
            transform: translate(0,-25%);
        }

        #${ uid }.euroland-tool-overlay-context-iframe .${ CLASS.OUTLET } > iframe {
            position: absolute;
            top: 0;
            left: 0;
            transition: opacity .4s ease-in-out;
            border-radius: 5px;
        }

        #${ uid }.euroland-tool-overlay-context-iframe .${ CLASS.OUTLET } > iframe.${ CLASS.COMPONENT_FRAME } {
            z-index: 100;
        }

        #${ uid }.euroland-tool-overlay-context-iframe .${ CLASS.OUTLET } > iframe.${ CLASS.PRERENDER_FRAME } {
            z-index: 200;
        }

        #${ uid }.euroland-tool-overlay-context-iframe .${ CLASS.OUTLET } > iframe.${ CLASS.VISIBLE } {
            opacity: 1;
            z-index: 200;
        }

        #${ uid }.euroland-tool-overlay-context-iframe .${ CLASS.OUTLET } > iframe.${ CLASS.INVISIBLE } {
            opacity: 0;
            z-index: 100;
        }

        @media screen and (max-width: 470px) {

            #${ uid }.euroland-tool-overlay-context-iframe .euroland-tool-overlay-iframe-container,
            #${ uid }.euroland-tool-overlay-context-iframe .${ CLASS.OUTLET } {
                min-width: 100%;
                min-width: calc(100% - 20px);

                max-width: 100%;
                max-width: calc(100% - 20px);
            }
        }

        #${ uid }.euroland-tool-overlay-context-iframe .${ CLASS.OUTLET } iframe {
            width: 1px;
            min-width: 100%;
            height: 100%;
        }

        @keyframes show-component {
            from {
                opacity: 0;
                -webkit-transform: translate(0,-25%);
                -ms-transform: translate(0,-25%);
                transform: translate(0,-25%);
            }

            to {
                opacity: 1;
                -webkit-transform: translate(0,0);
                -ms-transform: translate(0,0);
                transform: translate(0,0);
            }
        }

        @keyframes hide-component {
            from {
                opacity: 1;
                -webkit-transform: translate(0,0);
                -ms-transform: translate(0,0);
                transform: translate(0,0);;
            }

            to {
                opacity: 0;
                -webkit-transform: translate(0,-25%);
                -ms-transform: translate(0,-25%);
                transform: translate(0,-25%);
            }
        }

        .euroland-spinner {
            height: 30px;
            width: 30px;
            display: inline-block;
            box-sizing: content-box;
            opacity: 1;
            filter: alpha(opacity=100);
            animation: rotation .7s infinite linear;
            border-left: 8px solid rgba(0, 0, 0, .2);
            border-right: 8px solid rgba(0, 0, 0, .2);
            border-bottom: 8px solid rgba(0, 0, 0, .2);
            border-top: 8px solid #fff;
            border-radius: 100%
        }

        @keyframes rotation {
            from {
                -webkit-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                transform: rotate(0deg)
            }
            to {
                -webkit-transform: rotate(359deg);
                -ms-transform: rotate(359deg);
                transform: rotate(359deg)
            }
        }
    `;
}
