export const CLASS = {
    VISIBLE: 'visible',
    INVISIBLE: 'invisible'
};

export function getContainerStyle({ uid, width, height }) {
    return `
        #${ uid } {
            display: inline-block;
            position: relative;
            width: ${ width };
            height: ${ height };
        }

        #${ uid } > iframe {
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: opacity .2s ease-in-out;
        }

        #${ uid } > iframe.${ CLASS.INVISIBLE } {
            opacity: 0;
        }

        #${ uid } > iframe.${ CLASS.VISIBLE } {
            opacity: 1;
    }`;
}
