import { ZalgoPromise } from 'zalgo-promise';
import {
    waitForDocumentReady,
    watchForever,
    attributeToObject,
    getElementSafe,
    stringifyError,
    getGlobal,
    destroyGlobal,
    setDebug,
    cleanup,
    isElement,
    isNode,
    querySelectorAll,
    isLiveWatchEleemntDisabled
} from '../helpers';
import { FULLSCREEN_LIB, DEBUG } from '../constants';
import { ToolComponent } from '../shared/ToolComponent';
import { default as screenfull } from '../lib/screenfull';

const global = getGlobal();
const cleanupInstances = cleanup();
global[FULLSCREEN_LIB] = screenfull;

global.parentComponentInstances = [];

const getOrCreateParentComponent = ({ urlBase, toolName, companyCode, lang, toolVer, width, height, absoluteToolUrl }) =>  {
    let component = global.parentComponentInstances.find(instance => instance.tag.toLowerCase() === toolName.toLowerCase());
    
    if(!component) {
        component = {
            tag: toolName.toLowerCase(),
            instance: new ToolComponent({
                urlBase,
                toolName,
                companyCode,
                lang,
                toolVer,
                width,
                height,
                absoluteToolUrl
            })
        };

        global.parentComponentInstances.push(component);
    }

    return component.instance;
};

/**
 * Provives ability to disable watching element on host site.
 * Watching element on host site enabling the embed.js can monitor
 * and create a tool on the fly. This is handly for the host site which
 * is SPA, but not good at performance. If host site has any issue on the 
 * performance, they can set if to False by providing this variable before
 * embed.js is executed. 
 * e.g:
 * 
 * __EUROLAND_DISABLE_LIVE_WATCH_ELEMENT__ = false;
 */
const watchLiveElement = () => {
    
    watchForever({
        callback: (nodes) => {
            nodes.forEach(element => {
                const toolConfig = attributeToObject(element);
    
                getOrCreateParentComponent({...toolConfig}).render(element);
                cleanupInstances.register((function(needEmptyElm) {
                    const el = needEmptyElm;
                    return () => {
                        try{
                            while(el.firstChild) {
                                el.removeChild(el.firstChild);
                            }
                        }finally{
                            //
                        }
                    };
                })(element));
            });
        }
    });
    
};

export function bootstrap(selector) {
    waitForDocumentReady().then(() => {
        if(!selector) {
            console.warn('Not any selector provided.');
        }

        var tools = [];
        if(isElement(selector) || isNode(selector)) {
            tools = [selector];
        } else {
            //tools = document.querySelectorAll(selector);
            tools = querySelectorAll(selector);
        }
        
        tools.forEach(element => {
            const toolConfig = attributeToObject(element);
            
            getOrCreateParentComponent({...toolConfig}).render(element);

            cleanupInstances.register((function(needEmptyElm) {
                const el = needEmptyElm;
                return () => {
                    try{
                        while(el.firstChild) {
                            el.removeChild(el.firstChild);
                        }
                    }finally{
                        //
                    }
                };
            })(element));
        });
        

    })
    .catch(err => {
        console.warn(err);
    })
    .finally(() => {
        if(!isLiveWatchEleemntDisabled()) {
            watchLiveElement();
        }
    });
} 

export function create({ urlBase, container, name, company, language, version, width, height }) {
    return waitForDocumentReady().then(() => {
        container = getElementSafe(container);
        if (!container) {
            console.error(`Unable to get container to initialize the tool ${name}. Check the container ${container} is correct element or selector.`);
            //throw new Error(`Unable to get container to initialize the tool ${name}. Check the container ${container} is correct element or selector.`);
            return;
        }

        cleanupInstances.register((function(needEmptyElm) {
            const el = needEmptyElm;
            return () => {
                try{
                    while(el.firstChild) {
                        el.removeChild(el.firstChild);
                    }
                } finally {
                    //
                }
            };
        })(container));

        return getOrCreateParentComponent({
            urlBase: urlBase,
            toolName: name,
            companyCode: company,
            lang: language,
            toolVer: version,
            width: width,
            height: height
        }).render(container);

    }).catch(err => {
        console.error(`[euroland]: Unable to create tool ${name}. ERR: ${stringifyError(err)}`);
        //throw err;
    });
    

}

export function destroy() {
    zoid
        .destroy('Destroy all components')
        .then(cleanupInstances.all)
        .then(()=> destroyGlobal());
}

export function ____debug() { setDebug(true); }