import { getGlobal, isIFrame, isString, isObject, noop, EventEmitter } from '../helpers';
import { OverlayContainerTemplate } from './overlay/template';
import { ModalContainerTemplate } from './modal/template';
import { DefaultContainerTemplate } from './side-by-side/template';
import { defaultPrerenderTemplate } from './default-prerender-template';
import { DEBUG } from '../constants';
import { isDebug } from '../helpers/debug';

export function LeafComponentFactory(componentName, template, {
    tag,
    url,
    domain,
    bridgeUrl,
    method,
    props,
    dimensions,
    autoResize,
    attributes,
    defaultContext
}) {
    
    const global = getGlobal();
    const eventEmitter = new EventEmitter();
    const getContainerTemplate = (template) => {
        let {
            hideCloseButton = false,
            name,
            ignoreBackdropClick = false,
            customStyles,
            backdropBgColor,
            autoResize = true
        } = isObject(template) ? template : { name: isString(template) ? template : '' };

        backdropBgColor = backdropBgColor || 'rgba(0, 0, 0, 0.6)';
        
        switch (String(name).toLocaleLowerCase()) {
            case 'modal': return ModalContainerTemplate({
                eventEmitter: eventEmitter,
                autoResize: autoResize,
                hideCloseButton: hideCloseButton,
                backdropBgColor: backdropBgColor,
                ignoreBackdropClick: ignoreBackdropClick,
                styles: customStyles
            });
            case 'popup': return OverlayContainerTemplate({
                autoResize: autoResize,
                hideCloseButton: hideCloseButton,
                ignoreBackdropClick: ignoreBackdropClick,
                backdropBgColor: backdropBgColor,
                styles: customStyles
            });
            case 'default':
            default: return DefaultContainerTemplate({ eventEmitter: eventEmitter });
        }
    };
    const componentOptions = {
        tag: tag,
        url: url,
        domain: domain,
        bridgeUrl: bridgeUrl,
        method: method,
        props: props,
        dimensions: dimensions,
        autoResize: autoResize,
        attributes: attributes,
        prerenderTemplate: defaultPrerenderTemplate,
        validate: noop,
        defaultContext: defaultContext
    };

    var containerTemplate = getContainerTemplate(template);
    if (containerTemplate) {
        componentOptions.containerTemplate = containerTemplate;
    }

    global.components = global.components || {};

    if (!componentName) {
        throw new Error(`Invalid componentName value: ${componentName}`);
    }

    if (global.components[componentName]) {
        if(isDebug()) {
            console.debug(`There's already a registered component with name: ${ componentName }.`);
        }
        return global.components[componentName];
    }

    const createZoidComponent = () => {
        const component = zoid.create(componentOptions);
        
        if (isIFrame()) {
            component.componentOptions = componentOptions;
            component.containerTemplate = template;
        }

        return component;
    };

    // Gonna access to instance of component definition like: window.components.MyLoginComponent()
    const component = global.components[componentName] = createZoidComponent();
    return component;

}