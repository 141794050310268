import { isSameDomain } from '../helpers/window-util';

const pkg = require('../../package.json');

const URL_BASE = process.env.ORIGIN;

const formatVersion = (ver) => {    
    return ver.replace(/[^\d]+/g, '_');
};

export function getToolUrlBase() {
    return URL_BASE;
}

export function getGlobalKey() {
    return `___uid_integration_component_factory_${formatVersion(pkg.version)}___`;
}

export function getGlobal(win = window){
    const globalKey = getGlobalKey();

    if (!isSameDomain(win)) {
        throw new Error('Can not get global for window on different domain');
    }

    if (!win[globalKey]) {
        win[globalKey] = {};
    }

    return win[globalKey];
}

export function destroyGlobal() {
    const globalKey = getGlobalKey();
    delete window[globalKey];
}


export const __FLIPIT_INTEGRATION__ = {
    VERSION: pkg.version,
    URL_BASE: URL_BASE
};
