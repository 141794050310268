
import { noop } from './util';
import { PROTOCOL } from '../constants';

const IE_WIN_ACCESS_ERROR = 'Call was rejected by callee.\r\n';

export function isFileProtocol(win = window) {
    return win.location.protocol === PROTOCOL.FILE;
}

export function isAboutProtocol(win = window) {
    return win.location.protocol === PROTOCOL.ABOUT;
}

export function getParent(win = window) {

    if (!win) {
        return;
    }

    try {
        if (win.parent && win.parent !== win) {
            return win.parent;
        }
    } catch (err) {
        // pass
    }
}

export function getOpener(win = window) {

    if (!win) {
        return;
    }

    // Make sure we're not actually an iframe which has had window.open() called on us
    if (getParent(win)) {
        return;
    }

    try {
        return win.opener;
    } catch (err) {
        // pass
    }
}

export function canReadFromWindow(win) {
    try {
        // $FlowFixMe
        noop(win && win.location && win.location.href);
        return true;
    } catch (err) {
        // pass
    }

    return false;
}

export function getActualDomain(win = window) {

    const location = win.location;

    if (!location) {
        throw new Error('Can not read window location');
    }

    const protocol = location.protocol;

    if (!protocol) {
        throw new Error('Can not read window protocol');
    }

    if (protocol === PROTOCOL.FILE) {
        return `${ PROTOCOL.FILE }//`;
    }

    if (protocol === PROTOCOL.ABOUT) {

        const parent = getParent(win);
        if (parent && canReadFromWindow(parent)) {
            return getActualDomain(parent);
        }

        return `${ PROTOCOL.ABOUT }//`;
    }

    const host = location.host;

    if (!host) {
        throw new Error('Can not read window host');
    }

    return `${ protocol }//${ host }`;
}

export function getDomain(win = window) {

    const domain = getActualDomain(win);

    if (domain && win.mockDomain && win.mockDomain.indexOf(PROTOCOL.MOCK) === 0) {
        return win.mockDomain;
    }

    return domain;
}

export function isBlankDomain(win) {
    try {
        
        if (!win.location.href) {
            return true;
        }

        if (win.location.href === 'about:blank') {
            return true;
        }
    } catch (err) {
        // pass
    }

    return false;
}

export function isActuallySameDomain(win) {

    try {
        if (win === window) {
            return true;
        }

    } catch (err) {
        // pass
    }

    try {
        const desc = Object.getOwnPropertyDescriptor(win, 'location');

        if (desc && desc.enumerable === false) {
            return false;
        }

    } catch (err) {
        // pass
    }

    try {
        
        if (isAboutProtocol(win) && canReadFromWindow(win)) {
            return true;
        }
    } catch (err) {
        // pass
    }

    try {
        
        if (getActualDomain(win) === getActualDomain(window)) {
            return true;
        }

    } catch (err) {
        // pass
    }

    return false;
}

export function isSameDomain(win) {

    if (!isActuallySameDomain(win)) {
        return false;
    }

    try {

        if (win === window) {
            return true;
        }

        if (isAboutProtocol(win) && canReadFromWindow(win)) {
            return true;
        }

        if (getDomain(window) === getDomain(win)) {
            return true;
        }

    } catch (err) {
        // pass
    }

    return false;
}


export function assertSameDomain(win) {
    if (!isSameDomain(win)) {
        throw new Error('Expected window to be same domain');
    }

    return win;
}

export function getParents(win) {

    const result = [];

    try {

        while (win.parent !== win) {
            result.push(win.parent);
            win = win.parent;
        }

    } catch (err) {
        // pass
    }

    return result;
}

export function isAncestorParent(parent, child) {

    if (!parent || !child) {
        return false;
    }

    const childParent = getParent(child);

    if (childParent) {
        return childParent === parent;
    }

    if (getParents(child).indexOf(parent) !== -1) {
        return true;
    }

    return false;
}


const iframeWindows = [];
const iframeFrames = [];

export function isFrameWindowClosed(frame) {

    if (!frame.contentWindow) {
        return true;
    }

    if (!frame.parentNode) {
        return true;
    }

    const doc = frame.ownerDocument;

    if (doc && doc.documentElement && !doc.documentElement.contains(frame)) {
        let parent = frame;

        while (parent.parentNode && parent.parentNode !== parent) {
            parent = parent.parentNode;
        }

        // $FlowFixMe
        if (!parent.host || !doc.documentElement.contains(parent.host)) {
            return true;
        }
    }

    return false;
}

export function isWindowClosed(win, allowMock = true) {

    try {
        if (win === window) {
            return false;
        }
    } catch (err) {
        return true;
    }

    try {
        if (!win) {
            return true;
        }

    } catch (err) {
        return true;
    }

    try {
        if (win.closed) {
            return true;
        }

    } catch (err) {

        // I love you so much IE

        if (err && err.message === IE_WIN_ACCESS_ERROR) {
            return false;
        }

        return true;
    }


    if (allowMock && isSameDomain(win)) {
        try {
            if (win.mockclosed) {
                return true;
            }
        } catch (err) {
            // pass
        }
    }

    // Mobile safari

    try {
        if (!win.parent || !win.top) {
            return true;
        }
    } catch (err) {
        // pass
    }

    // This actually happens in IE. win === win errors out when the window
    // is from an iframe, and the iframe was removed from the page.

    try {
        noop(win === win); // eslint-disable-line no-self-compare
    } catch (err) {
        return true;
    }

    // IE orphaned frame

    const iframeIndex = iframeFrames.findIndex((item) => item === win);

    if (iframeIndex !== -1) {
        const frame = iframeFrames[iframeIndex];

        if (frame && isFrameWindowClosed(frame)) {
            return true;
        }
    }

    return false;
}

function cleanIframes() {
    for (let i = 0; i < iframeWindows.length; i++) {
        let closed = false;

        try {
            closed = iframeWindows[i].closed;
        } catch (err) {
            // pass
        }

        if (closed) {
            iframeFrames.splice(i, 1);
            iframeWindows.splice(i, 1);
        }
    }
}

export function linkFrameWindow(frame) {

    cleanIframes();

    if (frame && frame.contentWindow) {
        try {
            iframeWindows.push(frame.contentWindow);
            iframeFrames.push(frame);
        } catch (err) {
            // pass
        }
    }
}