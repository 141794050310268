import { isNode, setStyle } from './document-util';
import { isString } from './util';

export function createElement(tagName, attrs, events, ...children) {
    attrs = Object.assign(attrs || {});
    events = Object.assign(events || {});
    children = children || [];
    
    var el = (isNode(tagName)) ? tagName : document.createElement(tagName);
    
    Object.keys(attrs).forEach((key) => {
        if (attrs[key] !== undefined) {
            if (key === 'style') {
                for (let styleName in attrs[key]) {
                    el.style[styleName] = attrs[key][styleName];
                }
            }
            else if (key === 'id') {
                el.id = attrs[key];
            }
            else {
                el.setAttribute(key, attrs[key]);
            }
        }
    });
    
    Object.keys(events).forEach((key) => {
        if (typeof events[key] === 'function') {
            el.addEventListener(key, events[key]);
        }
    });
    if (children.length) {
        if (el.tagName === 'IFRAME') {
            const firstChild = children[0];
            if (children.length !== 1
                || !isNode(firstChild)
                || firstChild.tagName !== 'HTML'
            ) {
                throw new Error('Expected only single html element node as child of IFRAME element');
            }
        
            el.addEventListener('load', () => {
                const win = el.contentWindow;
    
                if (!win) {
                    throw new Error('Expected frame to have contentWindow');
                }

                const document = win.document;
                const docElement = document.documentElement;

                while (docElement.children && docElement.children.length) {
                    docElement.removeChild(docElement.children[0]);
                }

                docElement.appendChild(firstChild);
            });

        }
        else if (el.tagName === 'STYLE') {
            const firstChild = children[0];
            if (children.length !== 1
                || !isString(firstChild)
            ) {
                throw new Error('Expected only string as child of STYLE element');
            }

            setStyle(el, children);
        }
        else {
            var docFrag = document.createDocumentFragment();
    
            children.forEach(child => {
                docFrag.appendChild(isNode(child) ? child : document.createTextNode(String(child || '')));
            });
    
            el.appendChild(docFrag);
        }
    }

    return el;
}