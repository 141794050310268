import { EVENT, EUROLAND_TOOL, WINDOW_TYPE, PROPS_EVENT, FULLSCREEN_LIB } from '../../constants';
import {
    destroyElement,
    createElement,
    toCSS,
    uniqueID,
    supportsPopups,
    isIos,
    isFirefox,
    getGlobal,
    isRTL,
    ScrollBarHelper
} from '../../helpers';
import { getContainerSandboxStyle, CLASS } from './style';


export function ModalContainerTemplate({ eventEmitter, hideCloseButton = true, backdrop = true, ignoreBackdropClick = false, backdropBgColor }) {
    return function ({ context, doc, frame, prerenderFrame, event, close, focus, dimensions: { width, height } }) {
        if (!frame || !prerenderFrame) {
            return;
        }

        function closePopup(e) {
            e.preventDefault();
            e.stopPropagation();
            close();
        }

        function focusPopup(e) {
            e.preventDefault();
            e.stopPropagation();

            if (ignoreBackdropClick) {
                return;
            }

            if (context === WINDOW_TYPE.IFRAME) {
                // Close popup on backdrop clicked.
                if (e.currentTarget === e.target) {
                    close();
                }

                return;
            }

            if (!supportsPopups()) {
                return;
            }

            if (isIos()) {
                window.alert('Please switch tabs to reactivate the window');
            } else if (isFirefox()) {
                window.alert('Don\'t see the popup window?\n\nSelect "Window" in your toolbar to find out');
            } else {
                focus();
            }
        }

        const uid = `${EUROLAND_TOOL}-modal-${uniqueID()}`;
        const global = getGlobal();
        const scrollbar = new ScrollBarHelper(doc);
        
        prerenderFrame.classList.add(CLASS.VISIBLE);
        frame.classList.add(CLASS.INVISIBLE);

        const style = createElement('style', { type: 'text/css' }, null, getContainerSandboxStyle({
            uid: uid,
            hideCloseButton: hideCloseButton,
            backdrop: backdrop,
            backdropBgColor: backdropBgColor,
            dimensions: { width: width, height: height }
        }));
        
        const closeButton = (!hideCloseButton ?
            createElement('a', {
                'href': '#',
                'class': `${EUROLAND_TOOL}-modal-overlay-close`,
                'aria-label': 'close',
                'role': 'button'
            }, {
                'click': closePopup
            })
            : ''
        );
        const overlayIrameContainer = createElement('div',
            { 'class': `${EUROLAND_TOOL}-modal-overlay-iframe-container` },
            null,
            prerenderFrame,
            frame
        );
        
        const overlay = createElement(
            'div',
            { 'class': `${EUROLAND_TOOL}-modal-overlay` },
            {
                click: focusPopup
            },
            overlayIrameContainer,
            closeButton
        );

        const container = createElement('div',
            { id: uid },
            null,
            style,
            overlay
        );

        const adjustModal = () => {
            const isModalOverflowing = overlay.scrollHeight > doc.documentElement.clientHeight;
            const scrollbarWidth = scrollbar.getWidth();
            const isBodyOverflowing = scrollbarWidth > 0;

            if ((!isBodyOverflowing && isModalOverflowing && !isRTL()) || (isBodyOverflowing && !isModalOverflowing && isRTL())) {
                overlay.style.paddingLeft = `${scrollbarWidth}px`;
            }

            if ((isBodyOverflowing && !isModalOverflowing && !isRTL()) || (!isBodyOverflowing && isModalOverflowing && isRTL())) {
                overlay.style.paddingRight = `${scrollbarWidth}px`;
            }
        };

        const resetAdjustments = () => {
            overlay.style.paddingLeft = '';
            overlay.style.paddingRight = '';
        };

        if (eventEmitter) {
            eventEmitter.on(PROPS_EVENT.CHANGE_TITLE, (title) => frame.setAttribute('title', title));
            if (global[FULLSCREEN_LIB]) {
                eventEmitter.on(PROPS_EVENT.FULLSCREEN, () => global[FULLSCREEN_LIB].request(frame));
            }
        }

        event.on(EVENT.RENDERED, () => {
            prerenderFrame.classList.remove(CLASS.VISIBLE);
            prerenderFrame.classList.add(CLASS.INVISIBLE);

            frame.classList.remove(CLASS.INVISIBLE);
            frame.classList.add(CLASS.VISIBLE);

            setTimeout(() => {
                destroyElement(prerenderFrame);
            }, 1);
        });

        event.on(EVENT.RESIZE, ({ width: newWidth, height: newHeight }) => {
            if (typeof newWidth === 'number') {
                overlayIrameContainer.style.width = toCSS(newWidth);
            }

            if (typeof newHeight === 'number') {
                overlayIrameContainer.style.height = toCSS(newHeight);
            }

            adjustModal();
        });

        event.on(EVENT.CLOSE, () => {
            resetAdjustments();
            scrollbar.reset();
        });

        event.on(EVENT.DISPLAY, () => {
            scrollbar.hide();
            adjustModal();
        });

        return container;
    };
}