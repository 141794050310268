export function getUserAgent() {
    return window.navigator.userAgent;
}

export function isIos() {
    return (/iPhone|iPod|iPad/).test(getUserAgent());
}

export function isQQBrowser() {
    return (/QQBrowser/).test(getUserAgent());
}

export function isIosWebview() {
    if (isIos(getUserAgent())) {
        return (/.+AppleWebKit(?!.*Safari)|.*WKWebView/).test(getUserAgent());
    }
    return false;
}

export function isOperaMini() {
    return (/Opera Mini/i).test(getUserAgent());
}

export function isAndroid() {
    return (/Android/).test(getUserAgent());
}


export function isAndroidWebview() {
    if (isAndroid(getUserAgent())) {
        return (/Version\/[\d.]+/).test(getUserAgent()) && !isOperaMini(getUserAgent());
    }
    return false;
}

export function isFirefox() {
    return (/Firefox/i).test(getUserAgent());
}

export function isFirefoxIOS() {
    return (/FxiOS/i).test(getUserAgent());
}

export function isEdgeIOS() {
    return (/EdgiOS/i).test(getUserAgent());
}

export function isChrome() {
    return (/Chrome|Chromium|CriOS/).test(getUserAgent());
}

export function isSafari() {
    const ua = getUserAgent();
    return (/Safari/).test(ua) && !isChrome(ua);
}

export function isIE() {

    if (window.document.documentMode) {
        return true;
    }

    return Boolean(
        window.navigator &&
        window.navigator.userAgent &&
        (/Edge|MSIE|rv:11/i).test(window.navigator.userAgent)
    );
}

export function supportsPopups() {
    return !(isIosWebview() || isAndroidWebview() || isOperaMini() ||
        isFirefoxIOS() || isEdgeIOS() || isQQBrowser());
}