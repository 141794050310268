export function isRegex(item) {
    return Object.prototype.toString.call(item) === '[object RegExp]';
}

export function isEmptyObject(obj) {
    if (obj) {
        for (var prop in obj) {
            if (({}).hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
    }
  
    return JSON.stringify(obj) === JSON.stringify({});
}

export function  isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
}

export function  isString(item) {
    return Object.prototype.toString.call(item) === '[object String]';
}

export function isFunction(func) {
    return Object.prototype.toString.call(func) === '[object Function]';
}

export function isArray(arr) {
    return Object.prototype.toString.call(arr) === '[object Array]';
}

export function noop(...args) {
    // pass
}

export function getEmptyObject() {
    return {};
}

/**
 * Cancelable setInterval()
 * @param {Function} func actual func
 * @param {Number} time Time (miliseconds)
 * @returns {{cancel}}
 */
export function safeInterval(func, time) {

    let timeout;

    function loop() {
        timeout = setTimeout(() => {
            func();
            loop();
        }, time);
    }

    loop();

    return {
        cancel() {
            clearTimeout(timeout);
        }
    };
}


export function parseQuery(queryString) {
    
    const params = {};

    if (!queryString) {
        return params;
    }

    if (queryString.indexOf('=') === -1) {
        return params;
    }

    for (let pair of queryString.split('&')) {
        pair = pair.split('=');

        if (pair[0] && pair[1]) {
            params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }
    }

    return params;
}

export function getQueryParam(name) {
    return parseQuery(window.location.search.slice(1))[name];
}

export function arrayFrom(item) {
    return Array.prototype.slice.call(item);
}

export function isPerc(str) {
    return typeof str === 'string' && (/^[0-9]+%$/).test(str);
}

export function isPx(str) {
    return typeof str === 'string' && (/^[0-9]+px$/).test(str);
}

export function toNum(val) {

    if (typeof val === 'number') {
        return val;
    }

    const match = val.match(/^([0-9]+)(px|%)$/);

    if (!match) {
        throw new Error(`Could not match css value from ${ val }`);
    }

    return parseInt(match[1], 10);
}

export function toPx(val) {
    return `${ toNum(val) }px`;
}

export function toCSS(val) {

    if (typeof val === 'number') {
        return toPx(val);
    }

    return isPerc(val) ? val : toPx(val);
}

export function percOf(num, perc) {
    return parseInt(num * toNum(perc)  / 100, 10);
}

export function normalizeDimension(dim , max) {
    if (typeof dim === 'number') {
        return dim;
    } else if (isPerc(dim)) {
        return percOf(max, dim);
    } else if (isPx(dim)) {
        return toNum(dim);
    } else {
        throw new Error(`Can not normalize dimension: ${ dim }`);
    }
}

export function isIFrame(win = window) {
    try {
        if (win && win.parent && win.parent !== win) {
            return true;
        }
    } catch (err) {
        //pass
    }
    return false;
}

export function base64encode(str) {
    if ('function' == typeof btoa) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(m, p1) {
            return String.fromCharCode(parseInt(p1, 16));
        })).replace(/[=]/g, '');
    }
    if ('undefined' != typeof Buffer) {
        return Buffer.from(str, 'utf8').toString('base64').replace(/[=]/g, '');
    }
    throw new Error('Can not find window.btoa or Buffer');
}
export function uniqueID() {
    const chars = '0123456789abcdef';
    return 'uid_' + 'xxxxxxxxxx'.replace(/./g, function() {
            return chars.charAt(Math.floor(Math.random() * chars.length));
        }
    ) 
    + '_' 
    + base64encode((new Date).toISOString().slice(11, 19).replace('T', '.')).replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
}

const attPropMapping = [
    ['toolCompanyCode', 'companyCode'],
    ['toolName', 'toolName'],
    ['toolVersion', 'toolVer'],
    ['toolLanguage', 'lang'],
    ['toolApiVersion', 'apiVersion'],
    ['toolWidth', 'width'],
    ['toolHeight', 'height'],
    ['toolUrlBase', 'urlBase'],
    ['toolUrl', 'absoluteToolUrl'] // Use for development environment
];

/**
 * Converts attributes of a element into an object.
 * @param {HTMLElement} element 
 * @returns { companyCode, toolName, toolVer, lang, apiVersion, width, height, urlBase, absoluteToolUrl }
 */
export function attributeToObject(element) {
    var obj = {};
    for (const map of attPropMapping) {
        obj[map[1]] = (map[0] in Object.assign({}, element.dataset|| {}) === true) ? element.dataset[map[0]] : null;
    }

    return obj;
}

export function stringifyError(err, level) {
    void 0 === level && (level = 1);
    if (level >= 3) return 'stringifyError stack overflow';
    try {
        if (!err) return `<unknown error: ${{}.toString.call(err)}>`;
        if ('string' == typeof err) return err;
        if (err instanceof Error) {
            var stack = err && err.stack;
            var message = err && err.message;
            if (stack && message) return -1 !== stack.indexOf(message) ? stack : message + '\n' + stack;
            if (stack) return stack;
            if (message) return message;
        }
        return err && err.toString && 'function' == typeof err.toString ? err.toString() : {}.toString.call(err);
    } catch (newErr) {
        return 'Error while stringifying error: ' + stringifyError(newErr, level + 1);
    }
}