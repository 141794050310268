// "core-js" provides polyfill for old browsers,
//import 'core-js/stable';
import '../corejsImport';
//import 'regenerator-runtime/runtime';

/* Import all vendor.scss / css here[e.g. Import 'font-awesome/scss/font-awesome.scss';] */
//import { Routes, DefaultRoute } from './view/routes';

/* Include global app styles here, so that it will override component's css styles*/
//import './app.scss';

import { bootstrap, create as createTool, destroy, ____debug } from './bootstrap';
import { __FLIPIT_INTEGRATION__ } from '../helpers';

if (module.hot) {
    module.hot.accept();
}

if (process.env.NODE_ENV !== 'production') {
    console.log('Looks like we are in development mode!');
    console.log('ORIGIN ===>', process.env.ORIGIN, process.env.NODE_ENV, process.env.ZOID_FRAME_ONLY);
}

/**
 * Try to initialize pre-defined tool via selector `[euroland]` attribute.
 */
bootstrap('euroland-tool, [data-euroland-tool]');


/**
 * Export create() API to the module in order to be accessible via `euroland.create({})`.
 * Example:
 * ```
 *  euroland.createTool({
 *      container: 'mySelector',
 *      name: 'sharegraph',
 *      company: 's-volv',
 *      language: 'en-gb',
 *      version: '',
 *      width: '100%',
 *      height: '600px'
 *  });
 * 
 * ```
 */
 //const components = getGlobal().components = (getGlobal().components || {});
export {
    createTool,
    destroy,
    ____debug,
    //components,
    __FLIPIT_INTEGRATION__
};